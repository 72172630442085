export const  message = {
  'languageto': '中文',
  'Change Language To: ':'切换语言至: ',
  'US':'USS',
  'EU':'EUS',
  'GB':'GBS',
  'threeparse1gun':'3parse1gun',
  'singleparse1gun':'1parse1gun',
  'singleparse2guns':'1parse2guns',
  'singleparse3guns':'1parse3guns',
  'long_ct_0':'threeparse1gun, up to 21KW',
  'long_ct_1':'singleparse1gun, up to 7KW ',
  'long_ct_2':'singleparse2guns, up to 7KW x 2',
  'long_ct_3':'singleparse3guns, up to 7KW x 3',
  'chargerid':'Chargerid',
  'powercurrent':'PowerNow',
  'beeptime':'Data/UpTime',
  'rebootdate':'online,boot',
  'gunstandard':'GunStand',
  'chargertype':'ChargerType',
  'guestok':'GuestOK?',
  'boottimes':'Boots',
  'location':'NetworkIP',
  'ecurrent':'Current',
  'temperature':'Tempera',
  'power':'Power',
  'STOP':'STOP',
  'DOOR':'Door',
  'TEMP':'Tempera',
  'ECURRENT0':'Cur0',
  'ECURRENT1':'Cur1',
  'ECURRENT2':'Cur2',
  'GUNSTATE0':'GunST0',
  'GUNSTATE1':'GunST1',
  'GUNSTATE2':'GunST2',
  'GUNSWITCH0':'SWi0',
  'GUNSWITCH1':'SWi1',
  'GUNSWITCH2':'SWi2',
  'fmver':'FmVer',
  'operate':'Upgrade/Rebot/Active',
  'title_beeptime':'Last data time, every 2seconds in fast mode, 10 minites (charging)/30 minutes(no charging) in slow mode, wifi MAC for mouse hints',
  'title_rebootdate':'Last connected date, reboot date showed on mouse over',
  'title_gunstandard':'US, EU, CN supported',
  'title_chargertype':'threephasesimplegun, 1phase1gun, or 1phase 3 guns for max ',
  'title_guestok':'Start charging without authorized, or with authorized (has not implemented yet)',
  'guest_yes':'Charging without authorize',
  'guest_no':'Authorize needed Charging',
  'g_no':'Forbid',
  'g_yes':'Allow',
  'myuflaghint':'nickname or cellnumber for authorized charging',
  'searchhint':'Search for chargerid(4~6chs), MAC(12chs) or connect date(7~10chs, has -)',
  'searchuhint':'Search by usernick, cellnumber (if exists)',
  'title_boottimes':'Times of device boots, too many means device work abnormal.',
  'title_ecurrent':'ele Current of charging now',
  'title_location':'Network IP of the device',
  'title_temperature':'Temperature of Device',
  'title_power':'Power of Device',
  'title_fmver':'Firmware Version, can be upgrade when new version ready',
  'title_chargerid':'Charger device id, showed on QRCode, Can be searched in AWSIoT',
  'title_operate':'Firmware can be upgrade when new version ready, reboot operate or device active',
  'op_ota':'FwOTA',
  'op_reboot':'reBOT',
  'op_beep':'BEEP',
  'op_scanqr':'QRscan',
  'op_delete':'DELETE',
  'btn_save':'Save',
  'btn_more':'More',
  'btn_search':'Search',
  'btn_ok':'OK',
  'btn_user':'Usrs',
  'btn_remove':'DEL',
  'btn_device':'Devs',
  'btn_permto':'AuthDev',
  'chargerguns':'Guns',
  'online':'Online',
  'offline':'Offline',
  'stopdn':'Down',
  'normal':'Normal',
  'abnormal':'Abnormal',
  'closed':'Closed',
  'opened':'Open',
  'allowed':'Allowed',
  'forbid':'Forbidden',
  'state_charger':'OnlineState',
  'state_stop':'STOP State',
  'state_l_gnd':'GND State',
  'state_box':'Box State',
  'state_dev':'WorkingStat',
  'charge_pnp':'Plug & Play',
  'charge_guestok':'guestOK?',
  'charge_fwmver':'Fwm Ver',
  'charge_stand':'Standard',
  'charge_current':'Current',
  'charge_tempure':'Tempture',
  'charge_volplus':'VolofProto',
  'charge_volmin':'Ne Vol Proto',
  'charge_volmain':'powerVol',
  'charge_power':'ele Power',
  'gunleft':'Left',
  'gunmid':'Mid',
  'gunright':'Right',
  'startcharge':'Do Start Charge',
  'chargenopermit':'Not Authorized',
  'readyfree':'Ready',
  'readycard':'icCard',
  'gunin':'GunIN',
  'schedule':'schedule',
  'readycharging':'Charging',
  'readybadgnd':'GND Error',
  'stopdown':'StopDown',
  'stoped':'stoping',
  'offline':'OffLine',
  'onoffall':'ALL',
  'onlineonly':'Onl',
  'offlineonly':'Ofl',
  'dev_empty_title':'Device NOT found',
  'user_empty_title':'Customers NOT found',
  'dev_empty_context':'Authorization needed for data query<br/>Change query condition when "Device NOT found" shows<br/>Click on chargerid to enter charging page<br/>When new version ready, click version number to upgrade<br/>US/EU/GB guns supported in factory<br/>Three phase or single parse multi guns supported in factory<br/>Start charge without authorized by default<br/>With authorized charging can be set<br/>',
  'user_empty_context':'first, custom visit charging page<br/>second, custom fill nickname or cellnumber to identify<br/>third, manager setting [gustOK?] to authorize needed<br/>now, all customers showed in the page',
  'err_uflag':'For authorized charing, nickname or cell number is used to find you from others',
  'ok_uflag':'Your custom infomation has been set',
  'set_ok':'Configure success, Plz reboot device to valid',
  'set_fail':'Configure fail',
  'err_search1':'Search string too short, 2+ chars needed.',
  'err_search2':'Too many search strings, no more than 12',
  'err_search3':'ERROR: Search by date , the character "-" must be include',
  'err_imax':'Numberic needed here',
  'err_maxi':'Max current can not more than 32',
  'title_usr_cretime':'Date of account created, auto saved when first visit',
  'title_usr_lastactive':'Time of last visit, saved when scan to open',
  'title_usr_deviceos':'OS of visitors device',
  'title_usr_browser':'Browser type of visitors',
  'title_usr_ipaddress':'The IP address',
  'title_usr_uflag':'Nickname,usermobile or others setting by custom, use to find by',
  'title_usr_chgtimes':'Charger times',
  'title_usr_powall':'Power used (KWh)',
  'ucreatetime':'CreateTime',
  'ulastactive':'LastVisit',
  'udeviceos':'ClientOS',
  'ubrowser':'Browser',
  'uipaddress':'IPaddress',
  'uflag':'nickname',
  'uchgtimes':'Times',
  'upowall':'POWER',
  'autorefresh':'autoReload',
  'otaconfirm':'Are you sure firmware update?',
  'delconfirm':'Are you sure DELETE this charger data (device will lost managment) ?',
  'reupconfirm':'too long upgrade time used, reset data, want device back to normal green, are you sure?',
  'selector_mine':'Permitted Chargers',
  'selector_noauth':'No Permitted (Click to authorize)',
  'chargersearch':'Search by chargerid, pre 4 digits or more',

}
export const  message = {
  'languageto': 'EN',
  'Change Language To: ':'Change Language To: ',
  'US':'美标',
  'EU':'欧标',
  'GB':'国标',
  'threeparse1gun':'三相电桩',
  'singleparse1gun':'单相单枪',
  'singleparse2guns':'单相双枪',
  'singleparse3guns':'单相三枪',
  'long_ct_0':'三相单枪,最大21KW',
  'long_ct_1':'单相单枪,最大7KW',
  'long_ct_2':'单相双枪,最大7KW x 2',
  'long_ct_3':'单相三枪,最大7KW x 3',
  'chargerid':'电桩编号',
  'powercurrent':'当前电流',
  'beeptime':'数据更新时间',
  'rebootdate':'联网,开机',
  'gunstandard':'标准',
  'chargertype':'电桩类型',
  'guestok':'匿名充',
  'boottimes':'开机',
  'location':'联网IP',
  'ecurrent':'电流大小',
  'temperature':'设备温度',
  'power':'电表数',
  'STOP':'急停',
  'DOOR':'开箱',
  'TEMP':'温度',
  'ECURRENT0':'电流0',
  'ECURRENT1':'电流1',
  'ECURRENT2':'电流2',
  'GUNSTATE0':'插拔枪0',
  'GUNSTATE1':'插拔枪1',
  'GUNSTATE2':'插拔枪2',
  'GUNSWITCH0':'开关0',
  'GUNSWITCH1':'开关1',
  'GUNSWITCH2':'开关2',
  'fmver':'版本',
  'operate':'升级/重启/激活',
  'title_beeptime':'数据更新时间,快速模式两秒一次,慢速模式10分钟(充电中)/30分钟一次数据(未充电)',
  'title_rebootdate':'设备最后一次联网日期,鼠标悬停显示重启开机日期',
  'title_gunstandard':'充电枪标准，如：美标，欧标，中国国标',
  'title_chargertype':'本设备支持三相单枪，单相单枪，多枪',
  'title_guestok':'是否允许匿名启动充电',
  'guest_yes':'无需授权,任何人可启动充电',
  'guest_no':'需要授权,仅限授权用户启动',
  'g_no':'禁止',
  'g_yes':'允许',
  'myuflaghint':'授权充电需填写手机号',
  'searchhint':'输入电桩编号(4~6位),MAC(12位)或者联网日期搜索(7~10位有-号)',
  'searchuhint':'输入昵称/手机号查询',
  'title_boottimes':'设备启动次数，次数过多说明设备工作不正常',
  'title_ecurrent':'当前电流以大小，单位:A 点击可设置最大电流',
  'title_location':'设备上线的IP地址',
  'title_temperature':'设备温度',
  'title_power':'电能表，设备用电计量',
  'title_fmver':'固件版本',
  'title_chargerid':'充电桩编号,编号为0时可编辑',
  'title_operate':'执行相关操作',
  'op_ota':'升级',
  'op_reboot':'重启',
  'op_beep':'三响',
  'op_scanqr':'配码',
  'op_scanqr':'配码',
  'op_delete':'删除',
  'btn_save':'保存',
  'btn_more':'更多',
  'btn_search':'查找',
  'btn_ok':'确定',
  'btn_user':'客户',
  'btn_device':'设备',
  'btn_remove':'删除',
  'btn_permto':'电桩授权',
  'chargerguns':'充电枪数',
  'online':'在线',
  'offline':'离线',
  'stopdn':'按下',
  'normal':'正常',
  'abnormal':'异常',
  'closed':'关闭',
  'opened':'开启',
  'allowed':'允许',
  'forbid':'禁止',
  'state_charger':'联网状态',
  'state_stop':'急停状态',
  'state_l_gnd':'接地状态',
  'state_box':'机箱状态',
  'state_dev':'充电状态',
  'charge_pnp':'插枪直充',
  'charge_guestok':'匿名启动',
  'charge_fwmver':'固件版本',
  'charge_stand':'执行标准',
  'charge_current':'当前电流',
  'charge_tempure':'当前温度',
  'charge_volplus':'协议电压',
  'charge_volmin':'协议负压',
  'charge_volmain':'当前电压',
  'charge_power':'电表电量',
  'gunleft':'左枪',
  'gunmid':'中枪',
  'gunright':'右枪',
  'startcharge':'开始充电',
  'chargenopermit':'没有权限',
  'readyfree':'空闲中',
  'readycard':'已刷卡',
  'gunin':'已插枪',
  'schedule':'预约中',
  'readycharging':'充电中',
  'readybadgnd':'接地错',
  'stopdown':'急停中',
  'stoped':'停机中',
  'offline':'离线',
  'onoffall':'全部',
  'onlineonly':'在线',
  'offlineonly':'离线',
  'dev_empty_title':'没有找到设备',
  'user_empty_title':'没有客户数据',
  'dev_empty_context':'需要授权才能查询数据<br/>如已授权,请修改查询条件再试<br/>点击电桩编号可以打开充电功能页<br/>如有新固件,点击版本号可以升级<br/>点击重启可以实现远程启动<br/>设备可支持美标/欧标/中国国标充电枪<br/>设备可支持三相单枪及单相多枪<br/>系统默认启动充电无需授权，可设置为需要授权启动<br/>',
  'user_empty_context':'首先将设备的【匿名充】设置为需要授权启动<br/>然后选择需要授权的客户(客户需要提供账号)<br/>在本功能页面可查询所以客户的情况。',
  'err_uflag':'用户昵称或手机号用来区别账号,最少3位',
  'ok_uflag':'用户昵称或手机号码已设置成功',
  'set_ok':'设置成功,请重启设备生效',
  'set_fail':'设置失败',
  'err_search1':'搜索字符太少,最少需要4个字符',
  'err_search2':'搜索字符太多,最多12个字符',
  'err_search3':'错误：按日期查询，必需包含减号',
  'err_imax':'请填写一个有效的半角数字',
  'err_maxi':'最大电流不能超过32A',
  'title_usr_cretime':'账号创建日期，首次访问自动创建',
  'title_usr_lastactive':'最后一次访问日期,扫码自动记录',
  'title_usr_deviceos':'浏览器打开的电脑/手机操作系统',
  'title_usr_browser':'扫码打开的浏览器型号',
  'title_usr_ipaddress':'最后一次访问的网络IP地址',
  'title_usr_uflag':'客户自己设置的用户昵称，手机号码或者其他唯一标识信息，仅用于查询定位客户',
  'title_usr_chgtimes':'充电启动次数',
  'title_usr_powall':'充电消费电量总计(度)',
  'ucreatetime':'创建日期',
  'ulastactive':'最近访问',
  'udeviceos':'操作系统',
  'ubrowser':'浏览器',
  'uipaddress':'访问来源',
  'uflag':'账号/手机号',
  'uchgtimes':'次数',
  'upowall':'用电',
  'autorefresh':'自动刷新',
  'otaconfirm':'你确定要升级固件吗？',
  'delconfirm':'你确定要完全删除充电桩数据吗（可能导致设备无法联网）？',
  'reupconfirm':'升级过程中长时间无响应恢复数据设置，重新变绿，确定要执行这个操作吗？',
  'selector_mine':'已授权电桩',
  'selector_noauth':'未授权电桩 (点击编号可授权)',
  'chargersearch':'按电桩编号查询,最少可填前4个数字',
  
}